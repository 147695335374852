import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [{
    path: "*",
    redirect: "/index",
  },
  {
    path: "/system",
    name: "system",
    component: () => import("@/views/system-manage/system-manage.vue"),
    meta: {
      title: "系统页",
    },
    children: [{
      path: "/index",
      name: "index",
      component: () => import("@/views/system-manage/index/index.vue"),
      meta: {
        title: "首页",
      },
    }]
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;